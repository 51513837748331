import React, { useState, useRef, useLayoutEffect } from 'react'
import { LinkedInIcon, TwitterIcon, EmailIcon, UnderlineTeam } from './Icons'
import { useMedia, useWindowSize } from 'react-use'
import { useInView } from 'framer-motion/dist/framer-motion'
import ModalButton from './ModalButton'
import SanityImage from './SanityImage'
import cx from 'clsx'

const Team = ({ team, headline }) => {
	const [current, setCurrent] = useState(team[0]._key)
	const [previous, setPrevious] = useState(null)
	const [imageWidth, setImageWidth] = useState(null)
	const [delayHandler, setDelayHandler] = useState(null)
	const { width } = useWindowSize()
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.25, once: true })
	const isWide = useMedia('(min-width: 768px)')

	const handleHover = key => {
		setDelayHandler(setTimeout(() => {
			if (key !== current) setPrevious(current)
			setCurrent(key)
		}, 250))
	}

	const handleHoverLeave = () => {
		clearTimeout(delayHandler)
	}

	const handleCurrent = (key) => {
		if (key !== current) setPrevious(current)
		setCurrent(key)
		setTimeout(() => {
		}, 250)
	}

	useLayoutEffect(() => {
		if (width) {
			const loadWidth = width * 0.39
			isWide ? setImageWidth(Math.ceil(loadWidth)) : setImageWidth(600)
		}
	}, [width, isWide])

	return (
		<section
			id='team'
			ref={viewRef}
		>
			<div className={cx(
				'md:flex justify-between px-10 md:px-[6%]',
				inView && 'stagger-container'
			)}>
				<div className={cx(
					'flex-1 opacity-0',
					inView && 'stagger-container'
				)}>
					<h3 className='h3 pb-4 md:pb-0 md:-mb-2'>{headline}</h3>
					<UnderlineTeam className='hidden pointer-events-none w-full ml-[4%] h-auto md:block md:-mb-[4vw]' />
					<ul className='font-display pl-8 md:pl-[4%] xl:columns-2 xl:gap-x-20'>
						{team?.map((item) =>
							<li
								className='py-2 z-10'
								key={item._key}
							>
								{!isWide &&
									<ModalButton
										type={'team'}
										content={item}
										className=''
									/>}
								{isWide &&
									<button
										onMouseEnter={() => handleHover(item._key)}
										onMouseLeave={handleHoverLeave}
										onClick={() => handleCurrent(item._key)}
										onKeyDown={() => handleCurrent(item._key)}
										tabIndex={0}
									>
										<h6
											className={cx(
												'h6 text-left cursor-pointer hover:fade-color-in transition-colors whitespace-nowrap',
												current === item._key ? 'fade-color-in' : 'fade-color-out',
											)}
										>
											{item.name}
										</h6>
									</button>}
							</li>
						)}
					</ul>
				</div>
				<div className='hidden opacity-0 md:pl-[10%] md:flex-1 md:flex xl:relative'>
					<div className='w-full'>
						{team?.map(({ _key, photo, name, position, personDescription, personLinks, _rawPhoto }) =>
							<div
								key={_key}
								className={cx(
									'sticky top-4 max-h-fit',
									_key === current ? 'flex flex-col w-full xl:relative' : 'hidden',
								)}
							>
								<div className='relative self-start w-full overflow-hidden team-aspect'>
									<img
										src={photo?.asset?.metadata?.preview}
										alt={photo?.alt || ''}
										className='absolute w-full inset-0 object-contain -z-10'
									/>
									<SanityImage
										image={_rawPhoto}
										className='w-full object-cover h-full z-10'
										maxWidth={imageWidth}
										loading='lazy'
									/>
								</div>
								<div className='pr-[5%] z-10 pt-5 xl:flex-grow-0 bg-white xl:relative min-h-[14rem]'>
									<div
										className={cx(
											'space-y-4',
											_key === current ? 'animate-fadeIn block' : 'hidden',
											_key === previous ? 'animate-fadeOut' : ''
										)}
									>
										<div>
											<h4 className='h4'>{name}</h4>
											<h5 className='h5'>{position}</h5>
											{personLinks && <span className='flex space-x-5 pt-1 items-end'>
												{personLinks?.email &&
													<a
														href={`mailto:${personLinks.email}`}
														target='_blank'
														rel='noreferrer'
													>
														<EmailIcon className='w-4' />
													</a>}
												{personLinks?.linkedIn &&
													<a
														href={personLinks.linkedIn}
														target='_blank'
														rel='noreferrer'
													>
														<LinkedInIcon className='w-4 pb-[2px]' />
													</a>}
												{personLinks?.twitter &&
													<a
														href={personLinks.twitter}
														target='_blank'
														rel='noreferrer'
													>
														<TwitterIcon className='w-4' />
													</a>}
											</span>}
										</div>
										<p className='body-xs'>{personDescription}</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Team;