import React, { useRef } from 'react'
import { useInView } from 'framer-motion/dist/framer-motion'
import { ApplyButton } from '../components/Icons'
import cx from 'clsx'

export const Apply = ({ heading, link, subHeading }) => {
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.4, once: true })

	return (
		<section
			id='apply'
			className=''
			ref={viewRef}
		>
			<div className='bg-secondary  py-4 my-16'>
				<div className='flex justify-center h-full min-h-[170px] border-y-4 lg:border-y-8'>
					<div className={cx(
						'flex flex-col space-y-[11%] mt-[3.5%] mb-[2.5%] items-center justify-center overflow-visible',
						inView ? 'animate-view' : 'opacity-0'
					)}>
						<h3 className='h1-alt -rotate-3 overflow-visible px-8'>{heading}</h3>
						{subHeading &&
							<a
								href={link}
								target='_blank'
								rel="noreferrer"
								className='relative flex flex-col self-center items-center'
							>
								<p className='sub uppercase'>{subHeading}</p>
								<ApplyButton className='absolute w-[115%] top-0 bottom-0 mx-auto my-auto' />
							</a>}
					</div>
				</div>
			</div>
		</section>
	)
}
