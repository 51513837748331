import React, { useRef, useState, useLayoutEffect, useCallback } from "react"
import {
	useScroll,
	motion,
	useTransform,
	useInView
} from 'framer-motion/dist/framer-motion'
import { useMedia, useWindowSize } from 'react-use'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'
import { Perks } from './Perks'
import { getListSegments, getCardIndex } from "../utils"
import cx from 'clsx'

const Values = ({ props, perks }) => {
	const { headline, subHeadline, values, _rawImage } = props;
	const isWide = useMedia('(min-width: 1024px)')
	const imageSize = useImageSize(0.3104, .75, 'lg')
	const { width } = useWindowSize()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [previous, setPrevious] = useState(null)
	const [scrolling, setScrolling] = useState(false)
	const [current, setCurrent] = useState(values[currentIndex]._key)
	const [divHeight, setDivHeight] = useState(0)
	const [containerHeight, setContainerHeight] = useState(0)
	const scrollRef = useRef(null)
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 1, once: true })
	const containerRef = useRef(null)
	const segments = getListSegments(values)
	const [scrollSubHeight, setScrollSubHeight] = useState(`${(1 / values.length) * 100}%`)
	const allRefs = useRef({})

	const { scrollYProgress } = useScroll({
		target: scrollRef,
		offset: ['start end', 'end end']
	})

	const handleCurrent = useCallback((key) => {
		if (key !== current) setPrevious(current)
		setTimeout(() => {
			setCurrent(key)
		}, 450)
	}, [current])

	const handleScroll = (key) => {
		allRefs[key].scrollIntoView({ behavior: 'smooth', block: 'end' })
	}

	useLayoutEffect(() => {
		setScrollSubHeight(`${(1 / values?.length) * 100}%`)
	}, [values.length])

	useLayoutEffect(() => {
		return scrollYProgress.onChange((latest) => {
			setCurrentIndex(getCardIndex(latest, segments))
			handleCurrent(values[currentIndex]._key)
			setScrolling(latest > 0)
		})
	}, [currentIndex, scrollYProgress, handleCurrent, segments, values])

	useLayoutEffect(() => {
		viewRef && viewRef.current && setDivHeight(viewRef.current.clientHeight - 25)
	}, [width, viewRef])

	useLayoutEffect(() => {
		containerRef && setContainerHeight(containerRef.current.clientHeight)
	}, [width])

	const scale = useTransform(
		scrollYProgress,
		[0, 0.85],
		[0, 1],
	)

	const scaleMobile = useTransform(
		scrollYProgress,
		[0, 1],
		[0, 1],
	)

	return (
		<>
			<section id='values'>
				<div className={cx(
					'sticky top-0 pt-[5%] md:pt-[3%] opacity-0',
					inView && 'animate-view'
				)}>
					<div className='px-[3%]'>
						<h2 className='h2'>{headline}</h2>
						<p className='sub-sm pt-5'>{subHeadline}</p>
					</div>
					<div className='relative pt-[5%] px-[3%] md:pt-[3%] flex flex-col lg:grid lg:grid-cols-12'>
						<div className='col-start-1 col-end-4'>
							<div className='lg:flex relative lg:static'>
								<ul
									className={cx(
										'pb-5',
										inView && 'stagger-item'
									)}
									ref={viewRef}
								>
									{values.map(({ item, _key }) =>
										<li
											key={item}
											className='pb-[2%] lg:pb-[7%] last:pb-0 whitespace-nowrap opacity-0'
										>
											<button
												tabIndex={0}
												onClick={() => handleScroll(_key)}
												onKeyDown={() => handleScroll(_key)}
											>
												<h6
													className={cx(
														'h6 cursor-pointer max-w-max hover:fade-color-in',
														current === _key && scrolling ? 'fade-color-in' : 'fade-color-out',
													)}
												>
													{item}
												</h6>
											</button>
										</li>
									)}
								</ul>
                <div
                  className='h-[2px] lg:col-start-4 lg:col-span-1 lg:ml-auto xl:mx-auto bg-black origin-left lg:origin-top lg:h-full w-full lg:w-[3px] lg:right-0 xl:right-14 lg:bottom-0 absolute lg:top-[6%]'
                  style={{ maxHeight: divHeight }}
                />
                <motion.div
                  className='h-[2px] lg:col-start-4 lg:col-span-1 lg:ml-auto xl:mx-auto origin-left bg-alt lg:origin-top w-full lg:h-auto lg:w-[3px] z-10 lg:right-0 xl:right-14 lg:bottom-0 absolute lg:top-[6%]'
                  style={isWide ? { scaleY: scale, maxHeight: divHeight } : { scaleX: scaleMobile }}
                />
							</div>
						</div>
						{values.map(({ _key, details }) =>
							<div
								key={_key}
								className={cx(
									'min-h-[150px] pt-4 col-start-5 col-end-9 lg:pt-0 lg:px-[5%]',
									_key === current ? 'block animate-fadeIn' : 'hidden',
									previous === _key ? 'animate-fadeOut' : ''
								)}>
								<p className='body-xs min-h-[10vh] 2xl:body-sm max-w-xl mx-auto'>{details}</p>
							</div>
						)}
						<SanityImage
							viewportFadeIn
							loading='lazy'
							className='mix-blend-multiply w-fit max-h-96 px-8 mx-auto lg:px-0 lg:max-h-fit lg:-translate-y-[22%] 2xl:-translate-y-[15%] lg:w-full col-start-9 col-end-13'
							image={_rawImage}
							maxWidth={imageSize}
						/>
					</div>
					<div
						className='hidden lg:block lg:absolute lg:-mt-[22%]'
						ref={containerRef}
					>
						<Perks {...perks} />
					</div>
					<div
						style={{ height: isWide ? containerHeight : '0px' }}
						className='lg:-mt-[22%]' />
				</div>
				<div
					className='relative h-[4000px] -z-10'
					ref={scrollRef}
				>
					{values.map(({ _key }) =>
						<div
							key={_key}
							className='w-full'
							style={{ height: scrollSubHeight }}
							id={_key}
						>
							<div
								className='w-full h-8'
								ref={(ref) => allRefs[_key] = ref}
							/>
						</div>
					)}
				</div>
				{/* <div style={{ display: isWide ? 'block' : 'none', height: containerHeight, backgroundColor: 'blue' }} /> */}
			</section>
			<div className='block lg:hidden'>
				<Perks {...perks} />
			</div>
		</>
	);
}

export default Values;
