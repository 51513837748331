import React, { useRef } from 'react'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'
import { PortableText } from '../components/PortableText'
import { useInView } from 'framer-motion/dist/framer-motion'
import cx from 'clsx'

export const CareersAbout = ({ headline, _rawContent, _rawPhoto }) => {
	const imageSize = useImageSize(0.5614, 1, 'lg')
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.3, once: true })

	return (
		<section
			id='careersAbout'
			className='mx-0 sm:mx-[5%] lg:mx-0 relative mb-[3%]'
			ref={viewRef}
		>
			<div className='hidden lg:block absolute bg-primary right-0 top-0 bottom-0 w-2/3 -z-10' />
			<div className='lg:hidden absolute bg-primary inset-0 -z-10 mx-10' />
			<div className={cx(
				'relative py-[4%] lg:flex lg:flex-row-reverse lg:items-center',
				inView && 'stagger-container'
				)}>
				<div className='pb-5 mx-16 lg:px-[2.5%] lg:mx-0 opacity-0'>
					<h2 className='h3 pb-4'>{headline}</h2>
					<PortableText className='careers rich-text' blocks={_rawContent} />
				</div>
				<div className='md:min-w-[40%] lg:min-w-[60%] lg:pl-12 opacity-0'>
					<div className='relative pb-[56.9%]'>
						<SanityImage
							loading='lazy'
							image={_rawPhoto}
							maxWidth={imageSize}
							className='absolute left-0 w-full h-full object-cover px-2 rounded-[50px] xl:rounded-[150px]'
						/>
					</div>
				</div>
			</div>
		</section>
	)
}