import React, { useRef } from 'react'
import { useInView } from 'framer-motion/dist/framer-motion'
import { splitTitle } from '../utils'
import cx from 'clsx'

export const Perks = ({ headline, perks }) => {
	const viewRef = useRef(null)
	const heightRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.4, once: true })
	const splitHeadline = splitTitle(headline)

	return (
		<section
			id='perks'
			className='relative'
			ref={viewRef}
		>
			<div
				className='md:px-[7%]'
				ref={heightRef}
			>
				<div className={cx(
					'h2 -mb-6 md:-mb-12 md:p-0 max-w-max mx-auto md:max-w-none',
					inView ? 'animate-view' : 'opacity-0'
				)}>
					<h2 className='h2'>{splitHeadline.top}</h2>
					<h2 className='h2'>{splitHeadline.bottom}</h2>
				</div>
				<ul className='perks'>
					<div className='hidden md:block absolute bg-primary inset-0 -z-10 rounded-lg mx-[7%]' />
					{perks.map(({ _key, details, item }) =>
						<li
						key={_key}
						className={cx(
							'opacity-0 md:w-4/5 2xl:w-full',
							inView && 'animate-view'
						)}>
							<h3 className='h5 pb-3 md:pb-7'>{item}</h3>
							<p className='pl-8 pr-6 pb-8 body-sm md:p-0'>{details}</p>
						</li>
					)}
				</ul>
			</div>
		</section>
	)
}