import { graphql, useStaticQuery } from 'gatsby'

export const useCareers = () => {
	const data = useStaticQuery(graphql`
	query {
		careers: sanityCareer {
			about {
				_rawContent
				headline
				_rawPhoto
				photo {
					...Image
					alt
				}
			}
			hero {
				headline
				_rawImage
				image {
					...Image
					alt
				}
				link
				subHeadline
			}
			perks {
				headline
				perks {
					_key
					details
					item
				}
			}
			position {
				heading
				link
				subHeading
			}
			team {
				headline
				team {
					_key
					name
					personDescription
					personLinks {
						email
						linkedIn
						twitter
					}
					_rawPhoto
					photo {
						...ImageWithPreview
						alt
					}
					position
				}
			}
			values {
				headline
				subHeadline
				values {
					_key
					details
					item
				}
				_rawImage
				image {
					...Image
					alt
				}
			}
			seo {
				title
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
	`)
	return data
}
