import React from 'react'
import Meta from '../components/Meta'
import { useCareers } from '../hooks/useCareers'
import Hero from '../components/Hero'
import { CareersAbout } from '../components/CareersAbout'
import Values from '../components/Values'
import Team from '../components/Team'
import { Apply } from '../components/Apply'

const Careers = () => {
	const { careers } = useCareers()
	if (!careers) return null
	const { about, hero, perks, position, team, values, seo } = careers
	// const { about, hero, perks, position, values, seo } = careers

	return (
		<section id='careers'>
			<Meta pageTitle='Careers' props={seo} />
			<div className=''>
				<Hero {...hero} />
				<CareersAbout {...about} />
				<Values props={values} perks={perks} />
				<Apply {...position} />
				<Team {...team} />
			</div>
		</section>
	)
}

export default Careers
